<template>
  <v-container>
        <v-sheet rounded color="grey darken-3" class="white--text" elevation=1>
            <v-container>
                <v-row>
                    <v-col cols=12>
                        <h2 class='text-h4 font-weight-light'>Prestations Annexes</h2>
                    </v-col>
                </v-row>
                <v-row justify="center" class="px-2 px-lg-4">
                    <v-col cols="12" sm="6" lg="4" v-for='card in prestas' :key='card.title'>
                        <Card :card="card" />
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
  </v-container>
</template>

<script>
import Card from '@/components/Card';

    export default {
        name: "Misc",
        components: {
            Card
        },
        data(){
            return{
                prestas:[
                    {
                        title: "Photographe",
                        img: 'misc/photo.jpg',
                        pretext: 'Un site vitrine clé en main sans multiplier les interlocuteurs? C\'est possible!', 
                        posttext: 'Je me déplace sur la région toulousaine pour des prestations photos et réalisation vidéo de vos locaux et évènements, afin d\'habiller votre site sans faire appel à différents interlocuteurs'
                    },
                    {
                        title: "Modélisation 3D",
                        img: 'misc/blender.jpg',
                        pretext: 'Vous développez un concept mais n\'avez pas les visuels pour le mettre en avant ?',
                        posttext: 'Je réalise des modélisations 3D réalistes depuis plusieurs années. N\'hésitez pas à me faire part de vos interrogations sur la faisabilité de vos renders'
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>